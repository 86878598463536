import axios from 'axios'
import {vue} from '@/main.js'

function setInterceptor(instance) {
  instance.interceptors.response.use(
    (response) => {
      return response
    }, (error) => {
      if (error.response == null){
        return Promise.reject("No response from server")
      }
      if (error.response.status === 401) {
        vue.$root.$emit('system::loggedOut')
        // alert('You are not authorized')
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
      }
      return Promise.reject(error.message)
    })
  return instance
}



const api = {
  internal() {
    return axios.create()
  },
  base() {
    const headers = {
      'Content-Type': 'application/json',
      // Accept: '*!/!*',
    }
    return setInterceptor(axios.create({
      withCredentials: true,
      baseURL: window.config.apiUrl,
      timeout: 100000,
      headers,
    }))
  },
}

export default api
