export default function (other) {
  other = other || {}
  return {
    firstName: other.firstName,
    lastName: other.lastName,
    email: other.email,
    username: other.username,
    roleId: other.roleId,
    search: other.search,
    pagination: other.pagination,
    sort: other.sort,
  }
}
