import api from '@/api'

export default {
  namespaced: true,
  state: {
    sections: [],
    summaries:[],
    sources:[]
  },
  getters: {
    allSections(state){
      return state.sections || [];
    },
    allSummaries(state){
      return (state.summaries || []).sort();
    },
    allSources(state){
      return (state.sources || []).sort();
    },
    monitoringStatuses() {
      // return ['NONE', 'MONITORING', 'APPROACHING', 'REJECTED', 'APPROVED', 'COMPLETED', 'CANCELED', 'REMOVED']
      return ['MONITORING', 'APPROACHING', 'REMOVED'] // TODO: check if this is correct
    }
  },
  mutations: {
    setSections(state, data) {
      state.sections = data
    },
    updateData(state, data){
      state.sections = data.sections
      state.summaries = data.summaries
      state.sources = data.sources
    }
  },
  actions: {
    refreshData({ commit }) {
      return api.base()
        .get('/suit/data')
        .then(
          response => {
            commit('updateData', response.data.data)
          }
        )
    },
    getAllSuits(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/suit/get/all', {
          pagination,
          sort,
          ...(filter || {})
        })
        .then(
          response => ({
            data: response.data.data,
            metadata: response.data.metadata,
          })
        )
    },
    updateAllSections({commit}) {
      return api.base()
        .get('/suit/get/sections')
        .then(
          response => {
            commit('setSections',response.data.data)
            return response.data.data
          },
          err => ({ error: err.data }),
        )
    },
    getSuitById({commit},suitId) {
      return api.base()
        .get('/suit/get/id/'+suitId)
        .then(
          response => {
            return response.data.data
          },
          err => ({ error: err.data }),
        )
    },
    updateSuitMonitoring({commit}, { suitId, status, note }) {
      return api.base()
        .post('/suit/monitor/save',
          { suitId, status, note })
        .then(
          response => {
            return response.data.data
          },
          err => ({ error: err.data }),
        )
    },
    getSuitStats({commit},{from, until}) {
      return api.base()
        .post('/suit/stats',{from, until})
        .then(
          response => {
            return response.data.data
          },
          err => ({ error: err.data }),
        )
    },
    getSuitReviewStats({commit},{from, until}) {
      return api.base()
        .post('/suit/stats/reviews',{from, until})
        .then(
          response => {
            return response.data.data
          },
          err => ({ error: err.data }),
        )
    },
    logExternalAccess(actions,id) {api.base().get('/suit/checked/'+id);},
  },
}
