import api from '@/api'

export default {
  namespaced: true,
  state: {
    user: null,
    loggedIn: false,
    role: null,
    permissions: null,
    pingInterval: null
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn
    },
    user(state) {
      return state.user || {}
    },
    myId(state) {
      return state.user?.id
    },
    userFullName(state) {
      if (state.user == null) {
        return ''
      }
      return state.user.firstName + ' ' + (state.user.lastName || '')
    },
    myRole(state) {
      return state.role
    },
    hasPermission: (state) => (permission) => {
      return true;
      return state.permissions != null && state.permissions.has(permission)
    },
  },
  mutations: {
    updateUser(state, data) {
      state.user = data
      state.loggedIn = data != null && data.id != null
    },
    setPingInterval(state, interval) {
      state.pingInterval = interval
    }
  },
  actions: {
    checkConnection({
      commit,
      dispatch
    }) {
      return api.base()
        .get('/user/profile?full=true')
        .then(
          response => {
            let user = response.data.data
            let metadata = response.data.metadata
            commit('updateUser', user)
            dispatch('startPingSystem')
            commit('data/update',
              {
                // serverTimeDiff: (Date.parse(response.data.metadata.serverTime) - Date.now()),
                users: metadata.users, //metadata.users,
                userId: user.id,
                countries: metadata.countries,
                roles: metadata.roles,
                admin: metadata.admin,
              },
              { root: true }
            )
            return user
          })
    },
    login({
      commit,
      dispatch
    }, {
      username,
      password
    }) {
      return new Promise((resolve, reject) => {
        api.base()
          .post('/user/login', {
            username,
            password
          })
          .then(
            response => {
              let user = response.data.data
              let metadata = response.data.metadata
              commit('updateUser', user)
              dispatch('startPingSystem')
              resolve(user)
              commit('data/update',
                {
                  // serverTimeDiff: (Date.parse(response.data.metadata.serverTime) - Date.now()),
                  users: metadata.users, //metadata.users,
                  userId: user.id,
                  countries: metadata.countries,
                  roles: metadata.roles,
                  admin: metadata.admin,
                },
                { root: true }
              )
            },
            err => {
              reject(err)
            }
          )
      })
    },
    startPingSystem({
      state,
      commit
    }) {
      if (state.pingInterval == null && state.loggedIn) {
        let interval = setInterval(() => api.base()
          .get('/user/profile'), 30 * 1000)
        commit('setPingInterval', interval)
      }
    },
    logout(){
     return  api.base()
        .get('/user/logout')
    }
  }
}
