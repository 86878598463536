import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {return { x: 0, y: 0 }},
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      name: 'suits-all',
      path: '/pocessos/todos',
      component: () => import('./views/suits/AllSuits'),
      meta: {
        pageTitle: 'Todos os Processos',
        breadcrumb: [{ text: 'Processos', active: true }, { text: 'Todos os processos', active: true }]
      },
    },
    {
      name: 'suits-monitoring',
      path: '/pocessos/acompanhamento',
      component: () => import('./views/suits/MonitoringSuits'),
      meta: {
        pageTitle: 'Processos em acompanhamento',
        breadcrumb: [{ text: 'Processos', active: true }]
      },
    },
    {
      name: 'suits-interaction',
      path: '/pocessos/negociação',
      component: () => import('./views/suits/InteractedSuits'),
      meta: {
        pageTitle: 'Processos em negociação',
        breadcrumb: [{ text: 'Processos', active: true }]
      },
    },
    {
      name: 'config-points',
      path: '/config/points',
      component: () => import('./views/config/SuitPointView'),
      meta: {
        pageTitle: 'Configuração de pontos',
        breadcrumb: [{ text: 'Pontos', active: true }]
      },
    },
    {
      name: 'users',
      path: '/config/users',
      component: () => import('./views/users/UsersView'),
      meta: {
        pageTitle: 'Usuários',
        breadcrumb: [{ text: 'Usuários', active: true }]
      },
    },
    {
      name: 'config-about',
      path: '/config/about',
      component: () => import('./views/config/About'),
      meta: {
        pageTitle: 'Sobre o sistema',
        breadcrumb: [{ text: 'Sistema', active: true }]
      },
    },
    {
      name: 'suits-managed',
      path: '/pocessos/administrados',
      component: () => import('./views/suits/ManagedSuits'),
      meta: {
        pageTitle: 'Processos em acompanhamento',
        breadcrumb: [{ text: 'Processos', active: true }]
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  try { // Redirect to login page if user is not logged in and trying to access a restricted page
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    if (!authRequired) {
      return next()
    }
    let loggedIn = store.getters['user/loggedIn']
    if (!loggedIn) {
      await store.dispatch('user/checkConnection')
      loggedIn = store.getters['user/loggedIn']
    }
    console.log('loggedIn', loggedIn)
    if (!loggedIn) {
      return next('/login?returnUrl=' + to.fullPath)
    }
    next()
  } catch (e) {
    console.log(e)
    return next('/login?returnUrl=' + to.fullPath)
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
