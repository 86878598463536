
export default {
  namespaced: true,
  state: {
    users:{}
  },
  getters: {
    getUserFullName: (state) => (uId) => {
      let u = state.users[uId];
      if (u == null)
        return 'Unknown';
      return ((u.firstName || "") + " " + (u.lastName || "")).trim();
    },
    getUserAvatar: (state) => (uId) => {
      let u = state.users[uId];
      if (u == null)
        return require(`@/assets/images/avatars/1.png`);
      return require(`@/assets/images/avatars/${u.avatar || '1'}.png`);
    },
    getUserAcronyms: (state) => (uId , points) => {
      let u = state.users[uId];
      if(u == null){
        return "U" + (points === true ? "." : "");
      }
      let fn = (u.firstName || "").trim();
      let ln = u.lastName != null && u.lastName.trim() !== "" ? u.lastName.trim().charAt(0).toUpperCase()  :"";
      if (ln !== ""){
        return fn.charAt(0).toUpperCase() + (points === true ? "." : "") + ln;
      }else {
        return fn.charAt(0).toUpperCase() + (points === true ? "." : "")
      }
    },
    getOtherUsers(state) {
      return [];
    }
  },
  mutations: {
    update(state, data) {
      console.log('data.module->update()', data)
      if (data.serverTimeDiff != null) {
        state.serverTimeDiff = data.serverTimeDiff
      }
      if (data.users != null) {
        state.users = data.users.reduce((acc, u) => Object.assign(acc, {[u.id]: u}), {});
        }

      if (data.countries != null) {
        state.countries = data.countries
      }
      if (data.userId != null) {
        state.userId = data.userId
      }
      if (data.roles != null) {
        state.roles = data.roles
      }
      if (data.admin != null) {
        state.admin = data.admin
      }
    },
  },
  actions: {

  }

};
