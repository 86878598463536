import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store/index'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vSelect'
import '@/libs/fonts'
import '@/libs/vue2-perfect-scrollbar'
import '@/libs/vue-moment'
import '@/libs/v-calendar'
import i18n from '@/libs/i18n/index'


// BSV Plugin Registration
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

export const vue  = new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

window.onerror = function (message, source, line, column, error) {
  console.log('ONE ERROR HANDLER TO RULE THEM ALL:', {message, source, line, column, error});
}

