import api from '@/api'

export default {
  namespaced: true,
  state: {
  },
  getters: {
    getTypeDefaultIsPublic: (state) => (id) => {
      if (state.data.loaded !== true || state.data.types == null || state.data.types[id] == null) {
        return ''
      }
      return state.data.types[id].permission !== 1
    },
  },
  mutations: {

  },
  actions: {
    getNotes(actions, { suitId,  pagination, sort, filter }) {
      return api.base()
        .post('/note/get/suit/' + suitId, { pagination, sort, })
        .then(
          response => ({
            data: response.data.data,
            pagination: response.data.metadata,
            sort: response.data.metadata.sort
          }),
          err => ({ error: err.data }),
        )
    },
    //Get a Note
    get(actions, { id }) {
      return api.base()
        .get('/note/get/' + id)
        .then(
          response => (response.data.data),
          err => ({ error: err.data }),
        )
    },
    register({ dispatch }, { note }) {
      return api.base()
        .post('/note/add', note)
        .then(
          response => (response.data.data),
          err => ({ error: err.data }),
        )
    },
    update({ dispatch }, { id, note }) {
      note = { ...note, id }
      return api.base()
        .post('/note/update', note)
        .then(
          response => (response.data.data),
          err => ({ error: err.data }),
        )
    },
    deleteNote({ dispatch }, id) {

    },
  },
};
