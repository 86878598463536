import Vue from 'vue';

import VueMoment from 'vue-moment';
import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/he';


Vue.use(VueMoment, {moment: Moment});
Vue.filter('moment', function (value, format) {
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    if (format === 'from') {
        return Moment(value).fromNow()
    }
    return Moment(value).format(format)
});