import Vue from 'vue'
import Vuex from 'vuex'
// App Modules
import app from './theme/app'
import appConfig from './theme/app-config'
import verticalMenu from './theme/vertical-menu'
//System Modules
import suits from './system/suits'
import notes from './system/notes'
import user  from '@/store/system/user'
import data  from '@/store/system/data'
import suitPointConfig  from '@/store/system/suitPointConfig'
import users from '@/store/system/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app, appConfig, verticalMenu,
    suits, notes, user, data, suitPointConfig, users
  },
  strict: process.env.DEV,
})
